<template>
  <main>
    <CCard>
      <CCardBody class="d-flex justify-content-between">
        <div>
          <h5 class="text-info">
            結帳購物金發送時機
          </h5>
          <div>
            設定顧客結帳後，購物金發送的時機點，預設為<b class="text-success">付款狀態為已付款</b>時發送。
          </div>
          <div class="mt-4">
            <CSelect :label="'選擇發送時機'" description="若未選擇則為預設值。" :options="[{value: 'Logistics', label: '配送狀態為已取貨時發送'}, {value: 'Payment', label: '付款狀態為已付款時發送'}]" v-model="ShopSetting.IssueBonusTiming" :value.sync="ShopSetting.IssueBonusTiming" :placeholder="$t('Global.PleaseSelect')" />
          </div>
        </div>
      </CCardBody>
      <CElementCover
        v-if="(Submit === true)"
        :boundaries="[{ sides: ['center', 'center'], query: '#DeleteSubmit' }]"
        :opacity="0.8"
      >
        <CSpinner size="sm" color="primary"/>
      </CElementCover>
    </CCard>
    <CCard>
      <CCardBody class="d-flex justify-content-between">
        <div>
          <h5 class="text-info">
            啟用延遲發放購物金
          </h5>
          <div>
            啟用後並且顧客付款或已取貨後，將會於指定天數後發放購物金，顧客付款或已取貨當下不再立即發放購物金。
          </div>
          <div v-if="ShopSetting.EnableDelayIssueBonus" class="mt-4">
            <CInput :label="'延遲發送時間'" type="number" v-model="ShopSetting.DelayIssueBonusDay"
                    :placeholder="'未填寫則為預設值'" :description="'預設值為7天。'">
              <template #append-content>
                天
              </template>
            </CInput>
          </div>
          <CButton color="success" class="px-4 mt-3" @click="SetShopSetting()" size="sm">
            {{ $t('Global.Update') }}
          </CButton>
        </div>
        <CSwitch color="success" :checked.sync="ShopSetting.EnableDelayIssueBonus" />
      </CCardBody>
      <CElementCover
        v-if="(Submit === true)"
        :boundaries="[{ sides: ['center', 'center'], query: '#DeleteSubmit' }]"
        :opacity="0.8"
      >
        <CSpinner size="sm" color="primary"/>
      </CElementCover>
    </CCard>
    <CCard>
      <CCardBody class="d-flex justify-content-between">
        <div>
          <h5 class="text-info d-flex align-items-center">
            啟用購物金有效期限
            <CBadge class="ml-2" color="info" v-c-tooltip="{content: $t('Global.PreviewDescription'), placement: 'bottom'}">{{ $t('Global.Preview') }}</CBadge>
          </h5>
          <div>
            啟用後可設置購物金使用的有效期限，購物金一旦過期則額度會自動遺失，<b>重新開啟或關閉不影響已發送購物金之有效期限的時效</b>。
          </div>
          <div v-if="ShopSetting.EnableBonusExpired" class="mt-4">
            <CInput :label="'有效期限'" type="number" v-model="ShopSetting.BonusExpired"
                    :placeholder="'若啟用未填寫天數則預設有效期限為365天'" :description="'預設為365天有效期限。'">
              <template #append-content>
                天
              </template>
            </CInput>
            <CInput :label="'即將過期通知'" type="number" v-model="ShopSetting.BonusExpiredNotifyTime"
                    :placeholder="'若未填寫則預設為30天前通知'" :description="'預設為30天前通知顧客購物金即將過期。'">
              <template #append-content>
                天前通知
              </template>
            </CInput>
          </div>
          <CButton color="success" class="px-4 mt-3" @click="SetShopSetting()" size="sm">
            {{ $t('Global.Update') }}
          </CButton>
        </div>
        <CSwitch color="success" :checked.sync="ShopSetting.EnableBonusExpired" />
      </CCardBody>
      <CElementCover
        v-if="(Submit === true)"
        :boundaries="[{ sides: ['center', 'center'], query: '#DeleteSubmit' }]"
        :opacity="0.8"
      >
        <CSpinner size="sm" color="primary"/>
      </CElementCover>
    </CCard>
    <CCard>
      <CCardBody class="d-flex justify-content-between">
        <div>
          <h5 class="text-info d-flex align-items-center">
            設定購物金比值
            <CBadge class="ml-2" color="info" v-c-tooltip="{content: $t('Global.PreviewDescription'), placement: 'bottom'}">{{ $t('Global.Preview') }}</CBadge>
          </h5>
          <div>
            設定可使用的購物金與現金或其他錢包額度之折抵比例，預設值為1，即額度與購物金等值（比值為1:1）。
          </div>
          <div class="mt-4">
            <CInput :label="'現金比值'" type="number" v-model="ShopSetting.RatioCashBonus"
                    :placeholder="'未填寫則額度與購物金等值（比值為1:1）'" :description="`試算: 使用$${parseFloat(ShopSetting.RatioCashBonus || '1') * 100}購物金可於結帳時折抵$100現金，$100現金與$${parseFloat(ShopSetting.RatioCashBonus || '1') * 100}購物金等值。`">
            </CInput>
          </div>
          <CButton color="success" class="px-4 mt-3" @click="SetShopSetting()" size="sm">
            {{ $t('Global.Update') }}
          </CButton>
        </div>
      </CCardBody>
      <CElementCover
        v-if="(Submit === true)"
        :boundaries="[{ sides: ['center', 'center'], query: '#DeleteSubmit' }]"
        :opacity="0.8"
      >
        <CSpinner size="sm" color="primary"/>
      </CElementCover>
    </CCard>
  </main>
</template>

<route>
{
"meta": {
"label": "會員購物金"
}
}
</route>

<script>
export default {
  name: 'MemberBonus',
  layout: 'manage',
  components: {
  },
  data() {
    return {
      ShopSetting: {},
      Submit: false
    }
  },
  computed: {
  },
  created() {
    const PermissionSession = JSON.parse(localStorage.getItem('Permission'))
    this.Features = PermissionSession.Features.Organization ? (PermissionSession.Features.Organization.Member || []) : []
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        this.GetShopSetting()
      ]).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetShopSetting () {
      return this.$store.dispatch('InnerRequest', {
        url: '/system/get/ShopSetting',
        method: 'post'
      }).then(({data}) => {
        this.ShopSetting = data
        return true
      }).catch((err) => {
        throw err
      })
    },
    SetShopSetting () {
      this.Submit = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/set/ShopSetting',
        method: 'post',
        data: {
          ShopSetting: this.ShopSetting
        }
      }).then(() => {
        this.Submit = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.System/ModifySuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Submit = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.System/ModifyFail') + err.msg,
          type: (err.type ? err.type : 'error')
        })
      })
    },
  }
}
</script>

